<template>
  <b-card-actions :title="$t('Filter')" action-collapse :collapsed="false">
    <b-row class="filter">
      <!-- Transaction Id -->
      <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Transaction id") }}</label>
        <b-form-input
          v-model="filter.reference"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Type -->
      <!-- <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Payment type") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.type"
          :options="typeOptions"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-col> -->
      <!-- @input="(val) => $emit('update:typeFilter', val)" -->
      <!-- Currency -->
      <!-- <b-col
				cols="12"
				md="2"
				class="mb-md-0 mb-2"
			>
				<label>{{ $t('Currency') }}</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:value="currencyFilter"
					:options="currencyOptions"
					class="w-100"
					:reduce="val => val.id"
					label="value"
					@input="(val) => $emit('update:currencyFilter', val)"
				/>
			</b-col> -->
      <!-- Status -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Status") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.status"
          :options="statusOptions"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-col>
      <!-- @input="(val) => $emit('update:statusFilter', val)" -->
      <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Login Name") }}</label>
        <b-form-input
          v-model="filter.username"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Bank Account Code -->
      <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Bank account code") }}</label>
        <b-form-input
          v-model="filter.bank_account_code"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Bank Account Number -->
      <!-- <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Bank account number") }}</label>
        <b-form-input
          v-model="filter.bank_account_number"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col> -->
      <!-- Bank -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Bank") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.bank"
          :options="bankOptions"
          class="w-100"
          :reduce="(val) => val.id"
          label="bank_name"
        />
      </b-col>
      <!-- @input="(val) => $emit('update:bankFilter', val)" -->
      <!-- From Date Fiter -->
      <b-col cols="12" md="2" class="mb-2">
        <b-form-group :label="$t('From date')" label-for="fromDate">
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-form-group>
        <!-- @on-change="changeFromDate" -->
      </b-col>
      <!-- To Date Filter -->
      <b-col cols="12" md="2" class="mb-2">
        <b-form-group :label="$t('To date')" label-for="toDate">
          <flat-pickr
            id="toDate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-form-group>
        <!-- @on-change="changeToDate" -->
      </b-col>
      <!-- Tags -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Tags") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.tags"
          :options="tagsOptions"
          class="w-100"
          :reduce="(val) => val.id"
          label="name"
        />
      </b-col>
      <!-- @input="(val) => $emit('update:tagsFilter', val)" -->
      <!-- Action button -->
      <b-col cols="12" md="6" class="d-flex align-items-end justify-content-start pb-20">
        <b-button variant="outline-secondary" @click="resetFilter()">
          <feather-icon icon="RefreshCwIcon" size="16" /> {{ $t("Reset") }}
        </b-button>
        <b-button variant="primary" class="ml-2" @click="searchFilter()">
          <feather-icon icon="SearchIcon" size="16" /> {{ $t("Search") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { BFormGroup, BFormInput, BRow, BCol, BButton } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { integer } from "@core/utils/validations/validations";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [Number, null],
      default: null,
    },
    currencyFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    bankAccountCodeFilter: {
      type: [String, null],
      default: null,
    },
    bankAccountNumberFilter: {
      type: [String, null],
      default: null,
    },
    bankFilter: {
      type: [Number, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    tagsFilter: {
      type: [Array, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      default: null,
    },
    currencyOptions: {
      type: Array,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
    bankOptions: {
      type: Array,
      default: null,
    },
    tagsOptions: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      filter: {
        reference: this.referenceFilter,
        type: this.typeFilter,
        currency: this.currencyFilter,
        status: this.statusFilter,
        username: this.usernameFilter,
        bank_account_code: this.bankAccountCodeFilter,
        bank_account_number: this.bankAccountNumberFilter,
        bank: this.bankFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        tags: this.tagsFilter,
      },
	  flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      },
    };
  },
  methods: {
    changeFromDate() {
      this.$emit("update:fromdateFilter", this.filter.fromdate);
    },
    changeToDate() {
      this.$emit("update:todateFilter", this.filter.todate);
    },
    resetFilter() {
      this.filter = {
        reference: null,
        type: null,
        currency: null,
        status: null,
        username: null,
        bank_account_code: null,
        bank_account_number: null,
        bank: null,
        fromdate: null,
        todate: null,
        tags: null,
      };
      this.$emit("update:referenceFilter", null);
      this.$emit("update:typeFilter", null);
      this.$emit("update:currencyFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("update:usernameFilter", null);
      this.$emit("update:bankAccountCodeFilter", null);
      this.$emit("update:bankAccountNumberFilter", null);
      this.$emit("update:bankFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:tagsFilter", null);
    },
    searchFilter() {
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:typeFilter", this.filter.type);
      this.$emit("update:currencyFilter", this.filter.currency);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:bankAccountCodeFilter", this.filter.bank_account_code);
      this.$emit(
        "update:bankAccountNumberFilter",
        this.filter.bank_account_number
      );
      this.$emit("update:bankFilter", this.filter.bank);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:tagsFilter", this.filter.tags);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
