import { ref, watch, computed } from "@vue/composition-api";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useWithdrawalsList() {
  // Use toast
  const toast = useToast();

  const refWithdrawalListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#", class: "text-center" },
    { key: "ref", label: i18n.t("Transaction id") },
    { key: "username", label: i18n.t("Username"), stickyColumn: true },
    { key: "affiliate_id", label: i18n.t("Mã đại lý") },
    { key: "tags", label: "Tags" },
    { key: "type", label: i18n.t("Payment type") },
    { key: "fullName", label: i18n.t("Full Name") },
    { key: "member_bank_name", label: i18n.t("Member bank name") },
    {
      key: "member_bank_account_name",
      label: i18n.t("Member bank account name"),
    },
    {
      key: "member_bank_account_number",
      label: i18n.t("Member bank account no"),
    },
    // { key: 'currency', label: i18n.t('Currency') },
    { key: "created_at", label: i18n.t("Created at") },
    // { key: 'updated_at', label: i18n.t('Updated at') },
    { key: "amount", label: `${i18n.t("Amount")} Gross` },
    { key: "net_amount", label: `${i18n.t("Amount")} Net` },
    { key: "fee", label: i18n.t("Charge amount") },
    { key: "status", label: i18n.t("Status") },
    { key: "remark", label: i18n.t("Remark") },
    { key: "messageRefund", label: i18n.t("Message Refund") },
    { key: "action", stickyColumn: true, label: i18n.t("Actions"), class: "" },
  ];
  const perPage = ref(25);
  const totalWithdrawals = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const referenceFilter = ref(null);
  const typeFilter = ref(null);
  const currencyFilter = ref(null);
  const statusFilter = ref(null);
  const usernameFilter = ref(null);
  const bankAccountCodeFilter = ref(null);
  const bankAccountNumberFilter = ref(null);
  const bankFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const tagsFilter = ref(null);
  // const bankOptions = ref([]);
  // const currencyOptions = ref([]);

  const refetchData = () => {
    refWithdrawalListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      searchQuery,
      referenceFilter,
      typeFilter,
      currencyFilter,
      statusFilter,
      usernameFilter,
      bankAccountCodeFilter,
      bankAccountNumberFilter,
      bankFilter,
      fromdateFilter,
      todateFilter,
      tagsFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchWithdrawals = (ctx, callback) => {
    store
      .dispatch("payment-withdrawal/fetchWithdrawals", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        reference: referenceFilter.value,
        type: typeFilter.value,
        currency: currencyFilter.value,
        bank_account_code: bankAccountCodeFilter.value,
        bank_account_number: bankAccountNumberFilter.value,
        bankId: bankFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        stat: statusFilter.value,
        tags: tagsFilter.value,
      })
      .then((response) => {
        const { withdrawals, total, pageSize } = response;
        callback(withdrawals);
        totalWithdrawals.value = total;
        perPage.value = pageSize;
      })
      .catch((error) => {
        const { response } = error;
        toast({
          component: ToastificationContent,
          props: {
            title: response?.data.message || "Error fetching withdrawals list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // store.dispatch("local-setting-bank/fetchBanks", {}).then((response) => {
  //   bankOptions.value = response;
  // });

  

  const dataMeta = computed(() => {
    const localItemsCount = refWithdrawalListTable.value
      ? refWithdrawalListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWithdrawals.value,
    };
  });

  // store.dispatch("local-setting-currency/fetchCurrencies").then((response) => {
  //   if (response.data.code == "00") {
  //     currencyOptions.value = response.data.data;
  //   }
  // });

  // const statusOptions = ref([]);
  // store
  //   .dispatch("local-setting-currency/fetchOptionStatus")
  //   .then((response) => {
  //     statusOptions.value = response.data.data.map((val) => {
  //       return {
  //         label: val.name,
  //         value: val.id,
  //       };
  //     });
  //   });

  // const statusOptions = [
  //   { label: "pending", value: 1 },
  //   { label: "success", value: 2 },
  //   { label: "fail", value: 3 },
  //   { label: "confirm", value: 4 },
  //   { label: "request", value: 5 },
  //   { label: "reject", value: 6 },
  //   { label: "request process admin", value: 7 },
  // ];

  const typeOptions = [
    { label: "1Click Pay", value: 1 },
    { label: "WLBT", value: 2 },
  ];

  // const tagsOptions = ref([]);

  // store.dispatch("app-user-tag/fetchTagTypesFilter", {}).then((response) => {
  //   const { tagTypes } = response;
  //   tagsOptions.value = tagTypes;
  // });

  const resolvePaymentType = (pt) => {
    if (pt === 1) return "1Click Pay";
    if (pt === 2) return "WLBT";
    return "n/a";
  };

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  return {
    fetchWithdrawals,
    tableColumns,
    perPage,
    currentPage,
    totalWithdrawals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWithdrawalListTable,

    resolveStatus,
    refetchData,
    resolvePaymentType,

    // Extra Filters
    referenceFilter,
    typeFilter,
    currencyFilter,
    statusFilter,
    usernameFilter,
    bankAccountCodeFilter,
    bankAccountNumberFilter,
    bankFilter,
    fromdateFilter,
    todateFilter,
    tagsFilter,

    // statusOptions,
    typeOptions,
    // tagsOptions,
    // bankOptions,
    // currencyOptions,
  };
}
